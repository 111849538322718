import api from "@/modules/notifications/services/notification";

const state = () => ({
})

const getters = {
}

const actions = {
    send({ commit }, payload){
        if(payload.id){
            // Send in dashtaco 
            api.sendChannelEvent(payload).catch((e) => {commit('SET_API_DATA_FETCH_ERROR',e)});

            // Send in slack 
            api.sendSlackMessage(payload.id).catch((e) => {commit('SET_API_DATA_FETCH_ERROR',e)});
        }
    },

    pushNotification ({ commit }, notification) {
        api.postNotification(notification)
            .catch((e) => {commit('SET_API_DATA_FETCH_ERROR',e)});
    },
}

const mutations = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
