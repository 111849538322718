export default [
    {
        path: '/menu',
        name: 'menu',
        component: () => import('../views/Menu.vue'),
        meta: {
            guest: true,
            title: 'La carte',
            layout: 'default',
            metaTags: [
                {
                    name: 'description',
                    content: "Consulter la liste des menus et produits à la carte & concoctez-vous un repas copieux"
                },
                {
                    property: 'og:description',
                    content: "Consulter la liste des menus et produits à la carte & concoctez-vous un repas copieux"
                }
            ],
        }
    }
]
