export default [
    /**
     * Tacoloco
     **/
    {
        path: '/commander/:pdv',
        name: 'order',
        component: () => import('../views/Order.vue'),
        meta: {
            guest: true,
            stylesheet: 'public',
            title: 'Commander',
            layout: 'background-food-layout',
            param: true,
        }
    },
    {
        path: '/commander/detail',
        name: 'order_detail',
        component: () => import('../views/OrderDetail.vue'),
        meta: {
            guest: true,
            stylesheet: 'public',
            title: 'Détail de la commande',
            layout: 'background-food-layout',
            metaTags: [
                {
                    name: 'description',
                    content: 'Détail de votre commande chez tacoloco'
                },
                {
                    property: 'og:description',
                    content: 'Détail de votre commande chez tacoloco'
                }
            ]
        }
    },
    {
        path: '/commande/success/:id',
        name: 'checkout_success',
        component: () => import('../views/PaymentSucess.vue'),
        meta: {
            guest: true,
            stylesheet: 'public',
            title: 'Paiement accepté',
            layout: 'background-food-layout',
            state_payment: true
        }
    },
    {
        path: '/commande/error/:id',
        name: 'checkout_error',
        component: () => import('../views/PaymentError.vue'),
        meta: {
            guest: true,
            stylesheet: 'public',
            title: 'Paiement refusé',
            layout: 'background-food-layout',
            state_payment: false
        }
    }
]
