export default [
    {
        path: '/concept',
        name: 'concept',
        component: () => import('../views/Concept.vue'),
        meta: {
            guest: true,
            stylesheet: 'public',
            title: 'Concept',
            metaTags: [
                {
                    name: 'description',
                    content: "Découvrez les origines de la naissance du concept de tacoloco, des plats aux saveurs métissés à l'image des Antilles"
                },
                {
                    property: 'og:description',
                    content: "Découvrez les origines de la naissance du concept de tacoloco, des plats aux saveurs métissés à l'image des Antilles"
                }
            ],
            layout: 'default',
        }
    }
]
