<template>
  <div>
    <div class="offline-container">
      <p class="offline-text text-center">
        L'entreprise étant en <b>cours de liquidation</b>, la prise de commandes est désactivée. 
        Tacoloco.fr restera en ligne à des fins <b>démonstratives</b>
      </p>
    </div>
  </div>
</template>
<script>

export default {
  name: 'OfflineAlert'
}
</script>

<style>
.offline-container {
  padding: 0.3rem;
  width: 100%;
  z-index: 999999;
  display: flex;
  align-content: center;
  align-items: center;
  background: white;
  color: black;
  border-color: white;
  position: fixed;
  top: 0;
  font-size: 1rem;
  gap: 2rem;
  opacity: 1;
  visibility: visible;
  flex-wrap: wrap;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.offline-container.hide {
  opacity: 0;
  visibility: hidden;
}

.offline-container a {
  color: black !important;
  text-decoration: unset;
  font-weight: bold;
}

.offline-container a:hover {
  color: var(--hover-text);
}

.offline-container .offline-text {
  flex: 8 768px;
}

.offline-container .agree {
  flex: 1 150px;
  text-align: center;
}

.agree button {
  background: #9ADDF8;
  color: black;
  border: none;
  padding: 0.4rem 1.2rem;
  cursor: pointer;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: bold;
}

.agree button:hover {
  background: #9ADDF8;
  color: black;
}
</style>