import moment from "moment";
import fr from "@/core/plugins/fr";
moment.locale('fr', fr);
const filters = {
    price(x) {
        if(x){
            return (x.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$2,')) + '€';
        }else{
            return '0€'
        }
    },
    formatDate(x){
        return moment(x).format('dddd Do MMMM , HH:mm');
    },
    truncate(string, number){
        if (string.length > number) {
            return string.substring(0, number) + '...';
        }
        return string;
    },
    interval(date){
        return moment(date).fromNow();
    }
}

export default filters
