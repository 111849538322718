import { createApp } from 'vue'
import App from './App.vue'

import store from "@/core/store";
import router from '@/core/router'
import BootstrapVue3 from 'bootstrap-vue-3'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import '@/core/assets/css/font-awesome/css/font-awesome.min.css'
import '@/core/assets/css/animate.min.css'
import '@/core/assets/css/tacoloco.css'

import Default from "@/core/views/Default.vue";
import NoFooter from "@/core/views/NoFooter.vue";
import BackgroundFood from "@/core/views/BackgroundFood.vue"

import filters from "@/core/plugins/filters";

import "@/core/plugins/filters";
import "@/core/plugins/axios";

const app = createApp(App)

app.config.globalProperties.$filters = filters
app.component('DefaultLayout',Default)
app.component('NoFooterLayout',NoFooter)
app.component('BackgroundFoodLayout',BackgroundFood)

app.use(VueTelInput);
app.use(BootstrapVue3)
app.use(store)
app.use(router)
app.mount('#app')
