export default {
    init(){
        if(localStorage.getItem('cart') === null){
            localStorage.setItem('cart',JSON.stringify([]))
        }
    },
    getCarts() {
        this.init()
        return JSON.parse(localStorage.getItem('cart'));
    },
    postCart(obj) {
        this.init()
        const cartStorage = JSON.parse(localStorage.getItem('cart'))
        cartStorage.push(obj)
        localStorage.setItem('cart', JSON.stringify(cartStorage))
        return obj;
    },
    putCart(obj) {

        this.init()
        let cartStorage = JSON.parse(localStorage.getItem('cart'))
        cartStorage = cartStorage.map(item => {
            if (JSON.stringify(item) === JSON.stringify(obj)) {
                return Object.assign({}, item, obj)
            }
            return item
        })
        localStorage.setItem('cart', JSON.stringify(cartStorage))
        return JSON.parse(localStorage.getItem('cart'));
    },
    deleteCart(payload) {
        this.init()
        let cart = JSON.parse(localStorage.getItem('cart'))
        let index = cart.findIndex(x => JSON.stringify(x) === JSON.stringify(payload))
        cart.splice(index,1)
        localStorage.setItem('cart', JSON.stringify(cart))
        return JSON.parse(localStorage.getItem('cart')); // Return all cart with item splice
    },
    deletAllCart(){
        localStorage.removeItem('cart')
    }
};
