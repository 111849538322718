export default [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue'),
        meta: {
            guest: true,
            layout: 'default',
            title: 'Accueil',
            metaTags: [
                {
                    name: 'description',
                    content: '🌮 Page d\'accueil du site Tacoloco.fr 🌶'
                },
                {
                    property: 'og:description',
                    content: '🌮 Page d\'accueil du site Tacoloco.fr 🌶'
                }
            ]
        }
    }
]
