export default [
    {
        path: '/rejoignez-nous',
        name: 'joins-us',

        component: () => import('../views/JoinUsView.vue'),
        meta: {
            guest: true,
            stylesheet: 'public',
            title: 'Rejoignez-nous',
            layout: 'default',
        }
    }
]
