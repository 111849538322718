export default {
    init(){
        if(localStorage.getItem('outlet') === null){
            localStorage.setItem('outlet',JSON.stringify([]))
        }
    },
    getCurrentPdv() {
        this.init()
        return localStorage.getItem('outlet');
    },
    postPdv(obj) {
        this.init()
        localStorage.setItem('outlet', obj)
        return localStorage.getItem('outlet');
    }
};
