<template>
  <slot />
</template>

<script>
export default {
  name: 'BackgroundFood'
}
</script>

<style>
.shadow-card {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.special-div {
  margin-top: 5%;
}
.animateBounce:hover {
  animation: tada; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s; /* don't forget to set a duration! */
}
.order-background {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  background-image: url("@/core/assets/images/food/ECLATE.JPG");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: scroll;
}
</style>
