export default  (store) => {
    // inject a globally available $translate() method
    store.generateId = function() {
        let dt = new Date().getTime();
        const uuid = 'xxxxxxxx-xxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }
}
