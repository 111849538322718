export default  (store) => {
    store.compareObj = function(obj1, obj2) {
        let keysFound = [];
        Object.keys(obj1).forEach(key => {
            if(obj1[key] !== obj2[key]){
                keysFound.push(key)
            }
        });
        return keysFound;
    }
}