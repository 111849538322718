import Vuex from 'vuex'

import categories from "@/modules/category/store/categories"
import notifications from "@/modules/notifications/store/notifications";
import menus from "@/modules/menu/store/menus";
import cart from "@/modules/order/store/cart";
import orders from "@/modules/order/store/orders";
import uuidPlugin from "@/core/store/plugins/uuidPlugin";
import uniqIdPlugin from "@/core/store/plugins/uniqIdPlugin";
import compareObj from "@/core/store/plugins/compareObj";
import outlet from "@/modules/salespoint/store/outlet";

export default new Vuex.Store({
    modules: {
        categories: categories,
        notifications: notifications,
        outlet: outlet,
        menus: menus,
        cart:cart,
        orders: orders,
    },
    plugins:[uuidPlugin,uniqIdPlugin,compareObj]
});


