export default [
    {
        path: '/contactez-nous',
        name: 'contact',

        component: () => import('../views/ContactUs.vue'),
        meta: {
            guest: true,
            stylesheet: 'public',
            title: 'Contactez-nous',
            layout: 'default',
            metaTags: [
                {
                    name: 'description',
                    content: 'Vous souhaitez prendre contact ✉️ avec  Tacoloco, c\'est ici !'
                },
                {
                    property: 'og:description',
                    content: 'Vous souhaitez prendre contact avec  Tacoloco, c\'est ici !'
                }
            ]
        },
    }
]
