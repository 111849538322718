import api from "@/modules/order/services/axios/order";
import outlet from "../services/localstorage/outlet";

const state = () => ({
    order: [],
    orderAfterCheckout:[],
    pdv: '',
    orderView: {},
    apiRslt: {
        fetch: {
            inProgress: false,
            success: false,
            error: false,
        }
    }
})

const getters = {
    getPdv (state) {
        return state.pdv
    }
}

const actions = {
    addOutlet({ commit }, payload) {
        if(payload !== ''){
            const pdv = outlet.postPdv(payload)
            commit('ADD_PDV', pdv)
        }
    },
    getCurrentOutlet({ commit }){
        const pdv = outlet.getCurrentPdv()
        commit('LOAD_PDV', pdv)
    },
    checkout ({ commit }, newOrder) {
        commit('SET_API_DATA_FETCH_IN_PROGRESS')
        api.postOrderCheckout(newOrder)
            .then((r) => {
                window.location.href = r.data;
                commit('SET_API_DATA_FETCH_SUCCESS')
            })
            .catch((e) => {  commit('SET_API_DATA_FETCH_ERROR',e) });
    },
    retryPayment({ commit }, id){
        commit('SET_API_DATA_FETCH_IN_PROGRESS')
        api.postCheckoutRetry(id)
            .then((r) => {
                window.location.href = r.data;
                commit('SET_API_DATA_FETCH_SUCCESS')
            })
        .catch((e) => {commit('SET_API_DATA_FETCH_ERROR',e) }); 
    },
    getOrderWithCheckout({ commit }, id) {
        if(id !== ''){
            api.getOrder(id)
                .then((r) => {
                    commit('ADD_ORDER_AF_CHECKOUT', r.data)
                })
                .catch((e) => {commit('SET_API_DATA_FETCH_ERROR',e) });
        }
    },
    orderSuccess({ commit }, payload){
        api.sendSuccessMail(payload.id)
            .catch((e) => {commit('SET_API_DATA_FETCH_ERROR',e)});
    },
    stateOrder({ commit, dispatch }, payload){
        api.changeStateOrder(payload.id,payload)
            .then((r) => {
                if(r.data.state === 2 && r.data.statusPaid === 1){
                    dispatch('notifications/send', { id: r.data.id, pdv: r.data.pointVente },{root:true})
                }
            })
            .catch((e) => { commit('SET_API_DATA_FETCH_ERROR',e) });
    }
}

const mutations = {
    ADD_ORDER_AF_CHECKOUT (state, orderAfterCheckout) {
        state.orderAfterCheckout = orderAfterCheckout
    },
    ADD_ORDER (state, order) {
        state.order = order
    },
    ADD_PDV (state, pdv) {
        state.pdv = pdv
    },
    LOAD_PDV (state,pdv){
        state.pdv = pdv
    },
    // Result api
    SET_API_DATA_FETCH_IN_PROGRESS: (state) => {
        state.apiRslt.fetch.inProgress = true
        state.apiRslt.fetch.completed = false
        state.apiRslt.fetch.success = false
        state.apiRslt.fetch.error = false
    },
    SET_API_DATA_FETCH_SUCCESS: (state) => {
        state.apiRslt.fetch.inProgress = false
        state.apiRslt.fetch.completed = true
        state.apiRslt.fetch.success = true
        state.apiRslt.fetch.error = false
    },
    SET_API_DATA_FETCH_ERROR: (state) => {
        state.apiRslt.fetch.inProgress = false
        state.apiRslt.fetch.completed = true
        state.apiRslt.fetch.success = false
        state.apiRslt.fetch.error = true
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
