import axios from "axios";
export default {

    // @Todo: Token required
    getOrder(id) {
        return axios.get(`/orders/${id}`);
    },

    // @Todo: Token required
    getOrders() {
        return axios.get(`/orders?order[id]=desc`);
    },
    postOrderCheckout(obj){
        return axios.post(`/orders/checkout`, obj);
    },
    postCheckoutRetry(id){
        return axios.get(`/orders/retry/${id}`);
    },

    // @Todo: Token required
    putOrder(id, obj) {
        return axios.put(`/orders/${id}`, obj);
    },
    getOrderByCheckoutID(checkout){
        return axios.get(`/orders?stripeSessionID=${checkout}`);
    },
    //Sendmail
    sendSuccessMail(id){
        return axios.post(`/sendmail/order/${id}`);
    },

    // @Todo: Token required
    changeStateOrder(id,payload){
        return axios.put(`/orders/${id}`, payload);
    }
};
