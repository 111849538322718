<template>
  <div class="container-fluid">
    <slot />
  </div>
</template>

<script>
export default {
  name: "NoFooter"
}
</script>

<style scoped>

</style>
