<template>
  <div>
    <loader-overlay v-show="consent" :loader="false" />
    <div v-show="consent" class="cookie-container">
      <p class="cookie-text">
        Nous utilisons des cookies et des technologies similaires nécessaires au fonctionnement du site internet. Des
        cookies supplémentaires sont utilisés pour analyser le trafic. En continuant à utiliser notre site internet, vous
        acceptez l’utilisation de cookies.
        Pour plus d’informations, consulter notre <a href="#">Politique de confidentialité.</a>
      </p>
      <div class="agree">
        <button @click="agree">J'accepte !</button>
      </div>
    </div>
  </div>
</template>
<script>
import LoaderOverlay from "@/modules/order/components/LoaderOverlay.vue";

export default {
  name: 'CookieConsent',
  components: {
    LoaderOverlay
  },
  data() {
    return {
      consent: true
    }
  },
  created() {
    const consent = JSON.parse(localStorage.getItem('cookie-consent'))
    if(consent){
      this.consent = false
    }
  },
  methods: {
    agree() {
      this.consent = false
      localStorage.setItem('cookie-consent',true)
    }
  },
}
</script>

<style>
.cookie-container {
  z-index: 9999;
  display: flex;
  align-content: center;
  align-items: center;
  padding: 1rem 2rem;
  background: #FBE8A3;
  color: black;
  border-color: white;
  position: fixed;
  bottom: 0;
  font-size: 1rem;
  gap: 2rem;
  opacity: 1;
  visibility: visible;
  flex-wrap: wrap;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.cookie-container.hide {
  opacity: 0;
  visibility: hidden;
}

.cookie-container a {
  color: black !important;
  text-decoration: unset;
  font-weight: bold;
}

.cookie-container a:hover {
  color: var(--hover-text);
}

.cookie-container .cookie-text {
  flex: 8 768px;
}

.cookie-container .agree {
  flex: 1 150px;
  text-align: center;
}

.agree button {
  background: #9ADDF8;
  color: black;
  border: none;
  padding: 0.4rem 1.2rem;
  cursor: pointer;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: bold;
}

.agree button:hover {
  background: #9ADDF8;
  color: black;
}
</style>