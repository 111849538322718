export default [
    {
        path: '/mentions-legales',
        name: 'mentions',
        component: () => import('../views/Mentions.vue'),
        meta: {
            guest: true,
            stylesheet: 'public',
            title: 'Mentions Légales',
            layout: 'default',
            metaTags: [
                {
                    name: 'description',
                    content: "Mentions légales du site tacoloco.fr"
                },
                {
                    property: 'og:description',
                    content: "Mentions légales de vente  du site tacoloco.fr"
                }
            ],
        }
    },
    {
        path: '/conditions-generale',
        name: 'cgv',
        component: () => import('../views/ConditionGeneral.vue'),
        meta: {
            guest: true,
            stylesheet: 'public',
            title: 'Conditions Général',
            layout: 'default',
            metaTags: [
                {
                    name: 'description',
                    content: "Conditions générales de vente  du site tacoloco.fr"
                },
                {
                    property: 'og:description',
                    content: "Conditions générales de vente  du site tacoloco.fr"
                }
            ],
        }
    }
]
