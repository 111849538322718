import ls from '@/modules/order/services/localstorage/cart'
const state = () => ({
    cart: [],
    modalCart: false,
    addsucces: false
})
const getters = {
    cartSuppTotal(state){
        let total = 0
        state.cart.map(item =>{
            if(item.cat ==='menu') {
                let totalsupmenu = item.products.reduce((total, product) => {
                    total =  total + product.price
                    return total
                }, 0);
                total = total + totalsupmenu
            }
        })

        return total
    },
    //eslint-disable-next-line
    cartItemTotal: (state) => (item) => {
        let total = 0
        return total + item.price * item.qty + item.products.reduce((a, b) => a + (b.price * b.qty || 0), 0)
    },
    cartTotal (state) {
        return state.cart.reduce((total, product) => {
            total =  total + product.price * product.qty + product.products.reduce((a, b) => a + (b.price * b.qty || 0), 0)
            return total
         }, 0);
    },
    cartSubTotal(state){
        return state.cart.reduce((total, product) => {
            total =  total + product.price * product.qty
            return total
        }, 0);
    }
}
const actions = {
    addCartItemMenu({ commit,state }, payload){  
        const cartItem = state.cart.find(item => JSON.stringify(item) === JSON.stringify(payload))
        if (!cartItem) {
            const  product = ls.postCart(payload)
            commit('ADD_CART_ITEM', product)
            commit("ADD_CART_SUCCESS",true)
        } else {
            cartItem.qty++
            const  product = ls.putCart(cartItem)
            commit("UPDATE_CART_ITEM", product);
            commit("ADD_CART_SUCCESS",true)
        }
    },
    addCartItemProduct({ commit, state }, payload){
        const cartItem = state.cart.find(item => item.uuid === payload.uuid);
        if (!cartItem) {
            const  product = ls.postCart(payload)
            commit('ADD_CART_ITEM', product)
            commit("ADD_CART_SUCCESS",true)
        } else {
            cartItem.qty++
            const  product = ls.putCart(cartItem)
            commit("UPDATE_CART_ITEM", product);
            commit("ADD_CART_SUCCESS",true)
        }
    },

    incrementCartItem ({state, commit }, payload) {
        const cartItem = state.cart.find(item => JSON.stringify(item) === JSON.stringify(payload));
        if (cartItem) {
            cartItem.qty++
            const product = ls.putCart(cartItem)
            commit("UPDATE_CART_ITEM", product);
        }
    },
    decrementCartItem ({state, commit, dispatch}, payload) {
        const cartItem = state.cart.find(item => JSON.stringify(item) === JSON.stringify(payload));
        if(payload.qty > 1){
            cartItem.qty--
            const product = ls.putCart(cartItem)
            commit('UPDATE_CART_ITEM', product)
        }else {
            dispatch('removeCartItem',payload)
        }
    },
    //eslint-disable-next-line
    removeCartItem({state, commit}, payload) {
        const product = ls.deleteCart(payload)
        commit("REMOVE_CART_ITEM", product);
    },
    //eslint-disable-next-line
    removeAll ({state, commit}) {
        ls.deletAllCart()
        commit("REMOVE_CART");
    },
    loadCartItems ({ commit }) {
        const products = ls.getCarts()
        commit("LOAD_CART_ITEMS",products)
    },
    showCart({commit}){
        commit("MODAL_CART",true)
    },
    hideCart({commit}){
        commit("MODAL_CART",false)
    },
}

const mutations = {
    ADD_CART_ITEM (state, cart) {
        state.cart.push(cart)
    },
    UPDATE_CART_ITEM (state, payload) {
        state.cart = state.cart.map(item => {
            if (item.uuid === payload.uuid) {
                return Object.assign({}, item, payload)
            }
            return item
        })
    },
    REMOVE_CART_ITEM(state, payload) {
        state.cart = payload
    },
    REMOVE_CART(state) {
        state.cart = [];
    },
    LOAD_CART_ITEMS(state, cart) {
        state.cart = cart;
    },
    MODAL_CART(state,payload) {
        state.modalCart = payload;
    },
    ADD_CART_SUCCESS(state,payload) {
        state.addsucces = payload;
        setTimeout(() => {
            state.addsucces = false
          }, 2000)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
