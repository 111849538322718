import axios from "axios";
import moment from "moment";
export default {
    getNotifications() {
        return axios.get(`/notifications?order[id]=desc`);
    },
    postNotification(obj) {
        return axios.post('/notifications',obj)
    },
    sendChannelEvent(payload) {
        let notif = {
            title: "✨ Nouvelle commande",
            body : "Une nouvelle commande vient d'être passé sur tacoloco.fr",
            source : payload.pdv,
            isRead : false,
            createdAt:  moment(),
            type: 'order',
            extraID: 0,
            subscriptions:payload.pdv
        }
        notif.extraID = payload.id
        return axios.post('/notifications/channel',notif)
    },
    sendSlackMessage(id){
        return axios.post(`/slack/delivery/${id}`,{});
    }
};