<script setup>
import { RouterView } from 'vue-router'
</script>

<template>
  <component :is="layout">
    <router-link v-show="$route.name != 'home'" :to="{ name:'home' }" class="return text-center" data-cy="retour-accueil" @mouseover="upHere = true" @mouseleave="upHere = false">
      <img class="img-return me-2" src="@/core/assets/images/logos/Logo_rouge.png" alt="" />
      <span v-show="upHere" class="wording-return">Retour à l'accueil</span>
    </router-link>
    <RouterView />
  </component>
</template>

<script>

const default_layout = "default";
export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout';
    }
  },
  data() {
    return {
      upHere: false
    }
  },
}
</script>
<style>
.return {
  position: absolute;
  top: 15px; left: 30px;
  z-index: 99999
}
.img-return {
  width: 4rem;
}
.return:hover{
  animation: heartBeat;
  animation-duration: 2s;
}
.wording-return {
    font-family: 'Gochi Hand', cursive !important;
    font-size: 1rem;
    margin-bottom: 10px;
    color: black;
    text-align: center;
}
.mdi::before {
  font-size: 2rem;
  line-height: 3.5rem;
}
.btn .mdi::before {
  position: relative;
  top: 4px;
}
.btn-xs .mdi::before {
  font-size: 18px;
  top: 3px;
}
.btn-sm .mdi::before {
  font-size: 18px;
  top: 3px;
}
.dropdown-menu .mdi {
  width: 18px;
}
.dropdown-menu .mdi::before {
  position: relative;
  top: 4px;
  left: -8px;
}
.nav .mdi::before {
  position: relative;
  top: 4px;
}
.navbar .navbar-toggle .mdi::before {
  position: relative;
  top: 4px;
  color: #FFF;
}
.breadcrumb .mdi::before {
  position: relative;
  top: 4px;
}
.breadcrumb a:hover {
  text-decoration: none;
}
.breadcrumb a:hover span {
  text-decoration: underline;
}
.alert .mdi::before {
  position: relative;
  top: 4px;
  margin-right: 2px;
}
.input-group-addon .mdi::before {
  position: relative;
  top: 3px;
}
.navbar-brand .mdi::before {
  position: relative;
  top: 2px;
  margin-right: 2px;
}
.list-group-item .mdi::before {
  position: relative;
  top: 3px;
  left: -3px
}
</style>
