import axios from "axios";

export default {
    getOutlets() {
        return axios.get(`/point_ventes`);
    },
    postOutlet(obj) {
        return axios.post(`/point_ventes`, obj);
    }
};
