import { createRouter, createWebHistory } from 'vue-router'

import home from "@/modules/home/router/home";
import order from "@/modules/order/router/order";
import menu from "@/modules/menu/router/menu";
import concept from "@/modules/concept/router/concept";
import contact from "@/modules/contact/router/contact";
import joinus from "@/modules/joinus/router/joinus";
import mentions from "@/modules/mentions/router/mentions";
import hometaco from "@/modules/home/router/home";
import ordertaco from "@/modules/order/router/order";
import menutaco from "@/modules/menu/router/menu";

const router = createRouter({
  //eslint-disable-next-line
  history: createWebHistory(process.env.BASE_URL),
  routes: [
      ...home, ...menu, ...order,
      ...concept, ...contact, ...hometaco, ...joinus, ...mentions, ...menutaco, ...ordertaco,
      {
          path: '/:pathMatch(.*)*',
          component: () => import('../views/Error.vue'),
          meta: {
            title: 'Page Not Found',
            layout: 'no-footer',
          },
      }
  ],
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

// Meta keyword
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = 'Tacoloco.fr | ' + nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
      // Add the meta tags to the document head.
      .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router

