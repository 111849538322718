<template>
  <footer class="has-background-pink">
    <div class="container" style="margin-bottom: 0 !important;">
      <div class="row pt-3">
        <div class="col-md-5 col-12 text-start">
          <h5 class="title-size-1 mb-3">Venez nous voir !</h5>
          <p v-for="outlet in outlets" :key="outlet.name">
            <span v-if="outlet.isActive">
              <b class="text-uppercase">{{ outlet.name }}</b> <br>
              {{ outlet.description }} <br>
              {{ outlet.adresse }}
            </span>
          </p>
        </div>
        <div class="col-md-2 col-12 text-center">
          <img src="@/core/assets/images/logos/Logo_rouge.png" alt="" class="img-fluid img-footer">
        </div>
        <div class="col-md-5 col-12 text-end">
          <h5 class="title-size-1 mb-3">Nous contacter</h5>
          <p>
            0696 408 678 <br>
            <span v-for="outlet in outlets" :key="outlet.name">
              <span v-if="outlet.isActive">{{ outlet.email }}</span><br>
            </span>
          </p>
          <h5 class="title-size-1 mb-3">
            Suivez-nous !
          </h5>
          <a class="cursor mx-3" href="https://www.facebook.com/TacosMartinique" target="_blank">
            <span class="badge rounded-pill has-background-blue">
              <i class="fa fa-facebook" aria-hidden="true" />
            </span>
          </a>

          <a href="https://www.instagram.com/tacoloco_martinique" target="_blank" class="cursor">
            <span class="badge rounded-pill has-background-blue">
              <i class="fa fa-instagram" aria-hidden="true" />
            </span>
          </a>
        </div>
      </div>
      <div class="row pt-5">
        <div class="col-12 mx-auto my-auto">
          <p class="small mb-0 text-center">
            <router-link id="cgv" :to="{ name: 'cgv' }">CGV</router-link> - <router-link id="mentions" :to="{ name: 'mentions'}">MENTIONS LEGALES</router-link> - <router-link id="contact" :to="{ name: 'contact'}">CONTACT</router-link> - <router-link id="joinus" :to="{ name: 'joins-us'}">REJOIGNEZ-NOUS</router-link> <br>
            Site fait avec &#9829; par la collaboration <a href="#">ALÔ</a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "FooterLayout",
  computed:{
    ...mapState('outlet', ['outlets']),
  },
  methods:{
    ...mapActions('outlet', ['loadOutlets']),
  },
  created(){
    this.loadOutlets()
  }
}
</script>

<style scoped>

</style>
