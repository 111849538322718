import api from "@/modules/category/services/category";

const state = () => ({
    categories: [],
    apiRslt: {
        fetch: {
            inProgress: false,
            success: false,
            error: false,
        }
    }
})

const getters = {
}

const actions = {
    loadCategories({commit}) {
        api.getCategories().then(result => {
            commit('SAVE_CATEGORY', result.data);
        }).catch(error => {
            throw new Error(`API ${error}`);
        });
    }
}

const mutations = {
    SAVE_CATEGORY(state, categories) {
        state.categories = categories;
    },

    // Result api
    SET_API_DATA_FETCH_IN_PROGRESS: (state) => {
        state.apiRslt.fetch.inProgress = true
        state.apiRslt.fetch.completed = false
        state.apiRslt.fetch.success = false
        state.apiRslt.fetch.error = false
    },
    SET_API_DATA_FETCH_SUCCESS: (state) => {
        state.apiRslt.fetch.inProgress = false
        state.apiRslt.fetch.completed = true
        state.apiRslt.fetch.success = true
        state.apiRslt.fetch.error = false
    },
    SET_API_DATA_FETCH_ERROR: (state) => {
        state.apiRslt.fetch.inProgress = false
        state.apiRslt.fetch.completed = true
        state.apiRslt.fetch.success = false
        state.apiRslt.fetch.error = true
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
