<template>
  <div>
    <offline-alert />
    <div class="container-fluid">
      <slot />
    </div>
    <cookie-consent />
    <Footer />
  </div>
</template>

<script>
import CookieConsent from '@/modules/home/components/CookieConsent.vue';
import Footer from './layout/Footer.vue';
import OfflineAlert from './layout/OfflineAlert.vue';

export default {
  name: "DefaultLayout",
  components: {
    Footer,
    CookieConsent,
    OfflineAlert
  }
}
</script>

<style>
.container-fluid, .return {
  margin-top: 3rem;
}
</style>
